


































































































































































































































































































































.form-box {
  width: 800px;
  .el-input,.el-select,.el-cascader {
    width: 400px;
  }

}
